
import {
  Component, Vue,
} from "vue-property-decorator";
import { Action, State } from "vuex-class";
import {
  EntityPermission, FilePermission,
  TeamRole, TeamRoleDto, TeamRoleEntity, TeamRoleFile,
} from "@/model/team-role";
import { getTeamRolesModuleNamespace } from "@/store/store.utils";
import { TEAM_ROLES_ACTIONS, TEAM_ROLES_ENTITY_ACTIONS, TEAM_ROLES_FILE_ACTIONS } from "@/utils/team-role";
import { ObjectService } from "@/services/object.service";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

@Component({})
export default class TeamRoleDialog extends Vue {
  TEAM_ROLES_ACTIONS = TEAM_ROLES_ACTIONS;

  TEAM_ROLES_ENTITY_ACTIONS = TEAM_ROLES_ENTITY_ACTIONS;

  TEAM_ROLES_FILE_ACTIONS = TEAM_ROLES_FILE_ACTIONS;

  defaultRole: TeamRole | null = null;

  role: TeamRole | null = null;

  open = false;

  loading = false;

  tabControl = 0;

  tabItems = [
    {
      name: "Entity Permissions",
    },
    {
      name: "File Permissions",
    },
  ];

  @State("entities", getTeamRolesModuleNamespace())
  entities!: TeamRoleEntity[];

  @State("filesTypes", getTeamRolesModuleNamespace())
  filesTypes!: TeamRoleFile[];

  @Action("addOrSaveTeamRole", getTeamRolesModuleNamespace())
  addOrSaveTeamRole!: (role: TeamRoleDto) => Promise<void>

  get disableSave() {
    return this.role && (
      !this.role.name
        || !this.role.entityPermissions.every(it => it.entity && it.actions.length)
        || !this.role.filePermissions.every(it => it.fileType && it.actions.length)
        || ObjectService.isEqual(this.role, this.defaultRole)
    );
  }

  getTabClass(index) {
    if (!this.role) return "";

    const errorClass = "red--text";

    switch (index) {
      case 0:
        return !this.role.entityPermissions.every(it => it.entity && it.actions.length) && errorClass;
      case 1:
        return !this.role.filePermissions.every(it => it.fileType && it.actions.length) && errorClass;
      default:
        return "";
    }
  }

  isDisabledEntity(entity: TeamRoleEntity) {
    return this.role && this.role.entityPermissions.some(item => entity === item.entity);
  }

  isDisabledFileType(fileType: TeamRoleFile) {
    return this.role && this.role.filePermissions.some(item => fileType === item.fileType);
  }

  openDialog(role?: TeamRole) {
    this.defaultRole = role || new TeamRole("", "", [], [], []);
    this.role = this.defaultRole.copy();
    this.tabControl = 0;
    this.open = true;
  }

  addNewPermission(perms: (EntityPermission | FilePermission)[]) {
    if (!this.role) throw Error("Can't add entity perm without role");

    perms.push({ entity: null as any, actions: [] });
  }

  deletePermission(index: number, perms: (EntityPermission | FilePermission)[]) {
    if (!this.role) throw Error("Can't delete permission without role");

    perms.splice(index, 1);
  }

  isShowedAddEntityPermBtn(itemIndex: number) {
    return this.role
        && this.role.entityPermissions.length !== this.entities.length
        && itemIndex === this.role.entityPermissions.length - 1
        && this.role.entityPermissions.every(it => it.entity && it.actions.length);
  }

  isShowedAddFilePermBtn(itemIndex: number) {
    return this.role
        && this.role.filePermissions.length !== this.filesTypes.length
        && itemIndex === this.role.filePermissions.length - 1
        && this.role.filePermissions.every(it => it.fileType && it.actions.length);
  }

  async save() {
    if (!this.role) throw Error("Can't save empty role");

    this.loading = true;

    try {
      await this.addOrSaveTeamRole(this.role.toDto());
      this.open = false;
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }

    this.loading = false;
  }
}
